import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import Img from "gatsby-image"
import Container from "./Container"
import Row from "./Row"

const Header = () => {
  const data = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: { eq: "logo-outline.png" }) {
        childImageSharp {
          fixed(width: 205, height: 115) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)

  return (
    <header>
      <Container>
        <Row>
          <div className="col s6">
            <Link to="/" className="brand-logo pl4">
              <Img
                className="logo"
                fixed={data.placeholderImage.childImageSharp.fixed}
                alt="Beyond the Block, Inc."
                loading="eager"
              />
            </Link>
          </div>

          <div className="col s6 margin right-align pr5">
            <h5>Follow Us</h5>
            <ul>
              <li>
                <a
                  href="https://www.facebook.com/beyondtheblock"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <i className="fab fa-facebook fa-2x"></i>
                </a>
              </li>
              <li>
                <a
                  href="https://www.youtube.com/channel/UCKpaR3NCON1tipqnfBmXgnQ"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <i className="fab fa-youtube fa-2x"></i>
                </a>
              </li>
              <li>
                <a
                  href="https://twitter.com/beyondtheblock"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <i className="fab fa-twitter fa-2x"></i>
                </a>
              </li>
              <li>
                <a
                  href="https://www.instagram.com/beyondtheblockinc/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <i className="fab fa-instagram fa-2x"></i>
                </a>
              </li>
            </ul>
          </div>
        </Row>
      </Container>

      {/* Mobile Nav */}
      <ul className="sidenav" id="mobile">
        <li>
          <Link to="/" className="sidenav-close">
            Home
          </Link>
        </li>
        <li>
          <Link to="/#travels" className="sidenav-close">
            Travels
          </Link>
        </li>
        <li>
          <Link to="/#mission" className="sidenav-close">
            Mission
          </Link>
        </li>
        <li>
          <Link to="/#team" className="sidenav-close">
            Team
          </Link>
        </li>
        <li>
          <Link to="/#services" className="sidenav-close">
            Services
          </Link>
        </li>
        <li>
          <Link to="/#donate" className="sidenav-close">
            Donate
          </Link>
        </li>
        <li>
          <Link to="/#contact" className="sidenav-close">
            Contact
          </Link>
        </li>
        <li>
          <Link to="/blog" className="sidenav-close">
            Blog
          </Link>
        </li>
      </ul>

      {/* Desktop Nav */}
      <nav id="forFixedNav">
        <div className="nav-wrapper container">
          <a href="/" data-target="mobile" className="sidenav-trigger">
            <i className="material-icons hamburger">menu</i>
          </a>
          <ul id="nav-mobile" className="hide-on-med-and-down center-align">
            <li>
              <Link to="/">Home</Link>
            </li>
            <li>
              <Link to="/#travels">Travels</Link>
            </li>
            <li>
              <Link to="/#mission">Mission</Link>
            </li>
            <li>
              <Link to="/#team">Team</Link>
            </li>
            <li>
              <Link to="/#services">Services</Link>
            </li>
            <li>
              <Link to="/#donate">Donate</Link>
            </li>
            <li>
              <Link to="/#contact">Contact</Link>
            </li>
            <li>
              <Link to="/blog">Blog</Link>
            </li>
          </ul>
        </div>
      </nav>
    </header>
  )
}

export default Header
