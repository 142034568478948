import React from "react"
import Container from "./Container"
import Row from "./Row"

const Footer = () => (
  <footer className="page-footer">
    {/* Main Footer | Mission and Social */}
    <Container>
      <Row>
        <div className="col l6 s12">
          <p className="grey-text text-lighten-4 footer-text">
            The mission of Beyond the Block is to improve global awareness,
            increase intellectual curiosity, develop critical-thinking skills,
            and encourage completion of high school and post-secondary education
            in at-risk youth through the production of educational travel
            documentary videos, an accompanying curriculum, and other
            educational opportunities and materials.
          </p>
        </div>
        <div className="col l4 offset-l2 s12 align-right">
          <h5 className="white-text">Follow Us</h5>
          <ul>
            <li>
              <a
                href="https://www.facebook.com/beyondtheblock"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="fab fa-facebook fa-2x"></i>
              </a>
            </li>
            <li>
              <a
                href="https://www.youtube.com/channel/UCKpaR3NCON1tipqnfBmXgnQ"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="fab fa-youtube fa-2x"></i>
              </a>
            </li>
            <li>
              <a
                href="https://twitter.com/beyondtheblock"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="fab fa-twitter fa-2x"></i>
              </a>
            </li>
            <li>
              <a
                href="https://www.instagram.com/beyondtheblockinc/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="fab fa-instagram fa-2x"></i>
              </a>
            </li>
          </ul>
        </div>
      </Row>
    </Container>

    {/* Sub Footer | Copyright and Address */}
    <div className="footer-copyright">
      <Container>
        <Row>
          <div className="col l8 s12 footer-text">
            © {new Date().getUTCFullYear()} Beyond the Block, Inc.
          </div>
          <div className="col l4 s12 align-right footer-text">
            2622 19th Ave, San Francisco, CA 94116
          </div>
        </Row>

        <Row>
          <div className="col s12 center-align footer-text">
            <a
              href="https://www.holisticwebsolutions.com/"
              target="_blank"
              rel="noopener noreferrer"
              className="hws"
            >
              Created by Holistic Web Solutions
            </a>
          </div>
        </Row>
      </Container>
    </div>
  </footer>
)

export default Footer