import React from "react"
import Header from "./header"
import Footer from "./Footer"

const M = typeof window !== `undefined` ? require("materialize-css") : null

class Layout extends React.Component {
  componentDidMount() {
    setTimeout(function () {
      var sidenavElems = document.querySelectorAll(".sidenav")
      var sidenavInstance = M.Sidenav.init(sidenavElems, {
        preventScrolling: true,
        draggable: false,
      })

      var modalElems = document.querySelectorAll(".modal")
      var modalInstances = M.Modal.init(modalElems)
    }, 500)
  }
  render() {
    return (
      <div className="page-flexbox-wrapper" id="stickyNav">
        <Header />
        <main>{this.props.children}</main>
        <Footer />
      </div>
    )
  }
}

export default Layout
